import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Select, Spin, Tabs, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ceil, cloneDeep, map, size } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { upload_imageIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getEnquiry from "../../../newApi/enquiry/getEnquiry";
import upload from "../../../newApi/image/upload";
import { notificationEmail } from "../../../newApi/user";
import sendRegisterVerificationEmail from "../../../newApi/user/sendRegisterVerificationEmail";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import {
  arrayLengthCount,
  formatDate,
} from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import ReturnedMessageModal from "../../login/components/ReturnedMessageModal";
import createEnquiryRequest from "../../../newApi/enquiry/createEnquiryRequest";
import patchEnquiryReply from "../../../newApi/enquiry/patchEnquiryReply";
import getSystemConfigurationList from "../../../newApi/systemConfiguration/getSystemConfigurationList";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return false;
// }

// markup
const titleListData = ["Others"];
const CustomerServicePage = (props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [sentMessage, setSentMessage] = useState([]);
  const [returnedMessage, setReturnedMessage] = useState([]);
  // const [imageVisible, setImageVisible] = useState(false);
  const accessKey = get(props.user, "accessKey");
  const user = get(props, "user.user");
  // const [page, setPage] = useState(1);
  // const [total, setTotal] = useState(0);
  const [sentTotal, setSentTotal] = useState(0);
  const [receivedTotal, setReceivedTotal] = useState(0);
  const [sentPage, setSentPage] = useState(1);
  const [receivedPage, setReceivedPage] = useState(1);
  const [unreadTotal, setUnreadTotal] = useState(0);
  const [titleList, setTitleList] = useState([]);

  const PAGE_SIZE = 50;

  useEffect(() => {
    reset();
    getUserSentMessage();
    getRepliedMessageAPI();
    getUnreadMessageTotal();
    getTitleData();

  }, []);

  useEffect(() => {
    getUserSentMessage((sentPage - 1) * PAGE_SIZE);
  }, [sentPage]);

  useEffect(() => {
    getRepliedMessageAPI((receivedPage - 1) * PAGE_SIZE);
  }, [receivedPage]);

  useEffect(() => {
    getUnreadMessageTotal();
  }, [returnedMessage]);

  
  function getTitleData() {
    getSystemConfigurationList(1, 0, {
      key: "enquiry",
    })
      .then((res) => {
        // console.log(res);
        let data = get(res, "data");
        data = data[0] || {};
        let value = data?.value;

        let allTitle = value?.title || [];

        let tempTitle = [...allTitle, ...titleListData];

        setTitleList(tempTitle);
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.message);
      });
  }

  function getUserSentMessage(skip = 0) {
    getEnquiry(
      PAGE_SIZE,
      skip,
      {
        isReply: 0,
        profileId: get(user, "profile._id"),
      },
      accessKey
    )
      .then((res) => {
        setSentMessage(get(res, "data"), []);

        setSentTotal(get(res, "total"), 0);
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
  }

  function getUnreadMessageTotal(skip = 0) {
    getEnquiry(
      PAGE_SIZE,
      0,
      {
        isReply: 1,
        profileId: get(user, "profile._id"),
        isRead: 0,
      },
      accessKey
    )
      .then((res) => {
        setUnreadTotal(get(res, "total"), 0);
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
  }

  function getRepliedMessageAPI(skip = 0) {
    getEnquiry(
      PAGE_SIZE,
      skip,
      {
        isReply: 1,
        profileId: get(user, "profile._id"),
      },
      accessKey
    )
      .then((res) => {
        setReturnedMessage(get(res, "data"), []);

        setReceivedTotal(get(res, "total"), 0);
        getUnreadMessageTotal();
        //updateTotalAndCurrentPage(1); //update Message Received Page
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function postSubmitUpdatePage() {
    form.resetFields();
    getUserSentMessage();

    if (currentReceivedPage == totalReceivedPage) {
      currentReceivedPage - 1 == 0
        ? getRepliedMessageAPI()
        : setReceivedPage(currentReceivedPage - 1);
    } else {
      getRepliedMessageAPI((receivedPage - 1) * PAGE_SIZE);
    }

    reset();
    setLoading(false);
  }

  function reset() {
    form.resetFields();
    setImageUrl("");
    setFile([]);
  }

  const handleChange = (info) => {
    setFile(info.fileList);
    getBase64(info.file, (imageUrl) => {
      setImageUrl(imageUrl);
    });
  };

  const handleRead = (item) => {
    feathers("enquiry", accessKey)
      .patch(get(item, "_id"), {
        isRead: 1,
      })
      .then((res) => {
        postSubmitUpdatePage();
      });
  };

  function handleSubmit() {
    let submitObject = {};
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        if (get(file, "[0].originFileObj")) {
          setLoading(true);
          upload({ image: get(file, "[0].originFileObj") }).then((res) => {
            let res_data = get(res, "data");
            let image_url = get(res_data, "url");
            let image_key = get(res_data, "key");

            submitObject = {
              enquiryDate: new Date(),
              isReply: 0,
              messageTitle: values.messageTitle,
              profileId: get(user, "profile._id"),
              replyMessage: [],
              userMessage: values.messageContent,
              images: [{ url: image_url, key: image_key }],
            };

            createMessageRequest(submitObject);
          });
        } else {
          //no image uploaded:
          submitObject = {
            enquiryDate: new Date(),
            isReply: 0,
            messageTitle: values.messageTitle,
            profileId: get(user, "profile._id"),
            replyMessage: [],
            userMessage: values.messageContent,
            images: [],
          };

          createMessageRequest(submitObject);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(t("invalidform", sourceKey.profile));
        setLoading(false);
      });
  }

  const createMessageRequest = (submitObject = {}) => {
    createEnquiryRequest(submitObject)
      // feathers("enquiry", accessKey)
      //   .create(submitObject)
      .then((res) => {
        message.success(t("messageSent", sourceKey.profile));
        postSubmitUpdatePage();
        setMessageVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.message);
        setLoading(false);
      });
  };

  const patchReply = async (data, method) => {
    let patchObject = get(data, "images")
      ? {
          content: get(data, "content"),
          replyBy: get(data, "replyBy"),
          replyAt: get(data, "replyAt"),
          url: get(data, "images[0].url"),
        }
      : {
          content: get(data, "content"),
          replyBy: get(data, "replyBy"),
          replyAt: get(data, "replyAt"),
        };

    let oriReplyMessages = get(data, "oriReplyMessages")
      ? get(data, "oriReplyMessages")
      : [];
    let oriImages = get(data, "oriImages") ? get(data, "oriImages") : [];
    let patchImageObject = [...get(data, "images")];

    let correctValue = {
      isReply: 0,
      replyMessage: [...oriReplyMessages, patchObject],
      images: [...oriImages, ...patchImageObject],
      id: get(data, "patchId"),
      content: get(data, "content"),
    };

    patchEnquiryReply(correctValue)
      // feathers("enquiry", accessKey)
      //   .patch(get(data, "patchId"), correctValue)
      .then((res) => {
        res = res?.data;
        message.success(t("messageSent", sourceKey.profile));
        postSubmitUpdatePage();
        setSelectedData((prevState) => {
          return {
            ...prevState,
            replyMessage: get(res, "replyMessage"),
            images: get(res, "images"),
          };
        });
        let allReply = get(res, "replyMessage");
        let repliedMessage = allReply[size(allReply) - 1];
      })
      .catch((err)=>{
        console.log(err);
        message.error(err?.message)
      })
  };

  let totalSentPage = 0;
  let currentSentPage = 0;
  let totalReceivedPage = 0;
  let currentReceivedPage = 0;
  let statusNumber = 0;

  const updateTotalAndCurrentPage = (status) => {
    statusNumber = cloneDeep(status);

    if (status === 0) {
      totalSentPage = ceil(sentTotal / PAGE_SIZE);
      currentSentPage = sentPage;
    } else {
      totalReceivedPage = ceil(receivedTotal / PAGE_SIZE);

      currentReceivedPage = receivedPage;

      if (currentReceivedPage > totalReceivedPage + 1) {
        currentReceivedPage = totalReceivedPage;

        setReceivedPage(totalReceivedPage);
      }
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack
            suffix={
              <div className="justify-end flex items-center">
                {/* 客服{" "}
                <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery> */}
                {t("customerService", sourceKey.profile)}
              </div>
            }
          >
            <div className="py-5 px-3" style={{ paddingBottom: "450px" }}>
              <Tabs centered defaultValue={1} style={{ color: "black" }}>
                <Tabs.TabPane
                  tab={
                    <div className="text-center ml-2">
                      {/* 留言
                      <br /> */}
                      {t("message", sourceKey.profile)}
                    </div>
                  }
                  key={1}
                >
                  <div className="p-4">
                    <Form form={form} style={{ color: "black" }}>
                      <div className="text-base">
                        {/* 留言主题 <br /> */}
                        {t("messageTitle", sourceKey.profile)}
                      </div>
                      <div className="my-3">
                        <Form.Item
                          style={{ margin: 0 }}
                          name="messageTitle"
                          rules={[
                            {
                              required: true,
                              message: (
                                <React.Fragment>
                                  {/* 请输入留言主题
                                  <br /> */}
                                  {t("inputMessageTitle", sourceKey.profile)}
                                </React.Fragment>
                              ),
                            },
                          ]}
                        >
                          <Select
                            // showSearch
                            style={{
                              width: "100%",
                              // color: "white",
                            }}
                            className={"customer-service"}
                            // placeholder={t("con", sourceKey.asset)}
                            optionFilterProp="children"
                            autoComplete="new-user"
                            // filterOption={(input, option) =>
                            //   option.children
                            //     .toLowerCase()
                            //     .indexOf(input.toLowerCase()) >= 0
                            // }
                          >
                            {map(titleList, (item, index) => {
                              return (
                                <Select.Option
                                  value={item || ""}
                                  key={`option-${index}`}
                                >
                                  {item}
                                </Select.Option>
                              );
                            })}
                          </Select>

                          {/* <Input
                            className={"input-border"}
                            style={{
                              background: "rgba(32, 38, 47, 0.5)",
                              color: "black",
                            }}
                          /> */}
                        </Form.Item>
                      </div>

                      <div className="text-base">
                        {/* 留言内容 <br /> */}
                        {t("messageContent", sourceKey.profile)}
                      </div>
                      <div className="my-3">
                        <Form.Item
                          style={{ margin: 0 }}
                          name="messageContent"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: (
                          //       <React.Fragment>
                          //         {/* 请输入留言内容
                          //         <br /> */}
                          //         {t("inputMessageContent", sourceKey.profile)}
                          //       </React.Fragment>
                          //     ),
                          //   },
                          // ]}
                        >
                          <Input.TextArea
                            style={{
                              background: "rgba(32, 38, 47, 0.5)",
                              color: "black",
                            }}
                            rows={4}
                            className={"input-border"}
                          />
                        </Form.Item>
                      </div>
                      <div className="text-base">
                        {/* 上传图片 <br /> */}
                        {t("uploadImage", sourceKey.profile)}
                      </div>
                      <div className="my-3">
                        <Form.Item style={{ margin: 0 }} name="imageUpload">
                          <div
                            className="flex justify-center bg-white py-4"
                            style={{ background: "rgba(32, 38, 47, 0.5)" }}

                            // style={{ height: 200 }}
                          >
                            <Upload
                              name="avatar"
                              // listType="picture-card"
                              // className="avatar-uploader"
                              showUploadList={false}
                              accept="image/*"
                              fileList={file}
                              style={{ width: "100%" }}
                              beforeUpload={(file) => false}
                              onChange={handleChange}
                            >
                              <div className="m-4 ">
                                {" "}
                                {imageUrl ? (
                                  <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                ) : (
                                  <>
                                    <div className="flex justify-center">
                                      <img
                                        src={upload_imageIcon}
                                        style={{ width: 100, height: 80 }}
                                      />
                                    </div>

                                    <div className="font-semibold text-white">
                                      {/* 上传图片 Upload Image */}
                                      {t("uploadImage", sourceKey.profile)}
                                    </div>
                                  </>
                                )}
                              </div>
                            </Upload>
                          </div>
                        </Form.Item>
                      </div>
                      <div className="mt-10">
                        <Button
                          className="main-button-color input-border"
                          block
                          onClick={(e) => {
                            handleSubmit();
                          }}
                        >
                          {t("submit")}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <div className="text-center">
                      {/* 已发留言
                      <br /> */}
                      {t("messageSentTitle", sourceKey.profile)}
                    </div>
                  }
                  key={2}
                >
                  {updateTotalAndCurrentPage(0)}
                  <div className="p-4">
                    <div className=" greybox-bg-color box-shadow-center rounded-3xl p-3">
                      <div className="text-base font-semibold">
                        {/* 已发留言 Message Sent */}
                        {t("messageSentTitle", sourceKey.profile)}
                      </div>
                      <div className="my-5">
                        {map(sentMessage, (item, index) => {
                          return (
                            <div
                              className={`py-3 flex items-center justify-between ${
                                index != arrayLengthCount(sentMessage) - 1
                                  ? "border-b"
                                  : ""
                              }`}
                              key={`row-${index}`}
                              onClick={(e) => {
                                setSelectedData(item);
                                setMessageVisible(true);
                                setShowResponse(true);
                              }}
                            >
                              <span className="inline-block w-1/2">
                                <div className="text-truncate-twoline">
                                  {get(item, "messageTitle")}
                                </div>
                                <div className="truncate">
                                  {get(item, "userMessage")}
                                </div>
                                <div className=" font-thin">
                                  {get(item, "enquiryDate")
                                    ? formatDate(
                                        get(item, "enquiryDate"),
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""}
                                </div>
                              </span>
                              <span className="inline-block w-1/2 text-right text-red-500">
                                {/* 已发 Sent */}
                                {t("sent", sourceKey.profile)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {totalSentPage > 1 ? (
                    <div style={{ textAlign: "right" }}>
                      <LeftOutlined
                        style={{ marginRight: 20, cursor: "pointer" }}
                        onClick={() => {
                          if (sentPage > 1) {
                            setSentPage(sentPage - 1);
                          } else {
                            message.error("You are in the first page.");
                          }
                        }}
                      />
                      {`${currentSentPage} out of ${
                        totalSentPage === 0 ? 1 : totalSentPage
                      } pages`}

                      <RightOutlined
                        style={{ marginLeft: 20, cursor: "pointer" }}
                        onClick={() => {
                          if (
                            sentPage !== totalSentPage &&
                            totalSentPage !== 0
                          ) {
                            setSentPage(sentPage + 1);
                          } else {
                            message.error("You are in the last page.");
                          }
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <div className="text-center relative mr-6">
                      {/* 已回留言
                      <br /> */}
                      {/* Message Returned */}
                      {t("messageReturn", sourceKey.profile)}
                      {unreadTotal > 0 ? (
                        <span
                          className={`m-4 rounded-full bg-red-600 absolute`}
                          style={{ top: "-20px", left: "80px" }}
                        >
                          <span className="m-4 font-bold text-white">
                            {unreadTotal}
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                  key={3}
                >
                  {updateTotalAndCurrentPage(1)}

                  <div className="p-4">
                    <div className=" greybox-bg-color box-shadow-center rounded-3xl p-3">
                      <div className="text-base font-semibold">
                        {/* 已回留言 Message Returned */}
                        {t("messageReturn", sourceKey.profile)}
                      </div>
                      <div className="my-5">
                        {map(returnedMessage, (item, index) => {
                          return (
                            <div
                              className={`relative py-3 flex items-center justify-between ${
                                index != arrayLengthCount(returnedMessage) - 1
                                  ? "border-b"
                                  : ""
                              }`}
                              key={`row-${index}`}
                              onClick={(e) => {
                                setSelectedData(item);
                                setMessageVisible(true);
                                setShowResponse(true);
                                handleRead(item);
                              }}
                            >
                              <span className="inline-block w-1/2">
                                <div className="text-truncate-twoline">
                                  {get(item, "messageTitle")}
                                </div>
                                <div className="truncate">
                                  {get(item, "userMessage")}
                                </div>
                                <div className=" font-thin">
                                  {get(item, "enquiryDate")
                                    ? formatDate(
                                        get(item, "enquiryDate"),
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""}
                                </div>
                              </span>
                              {get(item, "isRead") === 0 ? (
                                <span
                                  className={` absolute m-2 rounded-full bg-red-600`}
                                  style={{ right: "120px" }}
                                >
                                  <span className="m-2 font-bold text-white">
                                    1
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                              <span className="inline-block w-1/2 text-right text-green-400">
                                {/* 查看 Click for detail */}
                                {t("clickDetails", sourceKey.profile)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {totalReceivedPage > 1 ? (
                    <div style={{ textAlign: "right" }}>
                      <LeftOutlined
                        style={{ marginRight: 20, cursor: "pointer" }}
                        onClick={() => {
                          if (receivedPage > 1) {
                            setReceivedPage(receivedPage - 1);
                          } else {
                            message.error(t("inFirstPage", sourceKey.asset));
                          }
                        }}
                      />
                      {`${currentReceivedPage} out of ${
                        totalReceivedPage === 0 ? 1 : totalReceivedPage
                      } pages`}

                      <RightOutlined
                        style={{ marginLeft: 20, cursor: "pointer" }}
                        onClick={() => {
                          if (
                            receivedPage !== totalReceivedPage &&
                            totalReceivedPage !== 0
                          ) {
                            setReceivedPage(receivedPage + 1);
                          } else {
                            message.error(t("inLastPage", sourceKey.asset));
                          }
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </NavHeader>
        </Spin>
      </Layout>

      <ReturnedMessageModal
        visible={messageVisible}
        data={selectedData}
        showResponse={showResponse}
        onClose={() => {
          setMessageVisible(false);
        }}
        onSubmit={patchReply}
      ></ReturnedMessageModal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerServicePage);
