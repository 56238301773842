import { apiUrl, _axios_base_get_list } from "..";

export default async function getSystemConfigurationList(limit, skip, query = {}) {
  return await _axios_base_get_list(
    `${apiUrl}/system-configuration/getSystemConfigurations`,
    limit,
    skip,
    query
  );
}
