import { UserOutlined } from "@ant-design/icons";
import { Avatar, Comment, Tooltip } from "antd";
import { compact, get, isEqual } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import getProfiles from "../../../newApi/profile/getProfiles";
import LightBoxGallery from "../../general/components/LightBoxGallery";

function ChatCard(props) {
  const [username, setUsername] = useState("");
  const { data } = props;
  const { userId } = props;
  const image = [get(data, "url")];

  getProfiles(1, 0, { userObjId: get(data, "replyBy") })
    .then((res) => {
      setUsername(get(res, "data[0].populatedUserId.username"));
    })
    .catch((err) => {
      console.log(err);
    });

  let replyAt = get(data, "replyAt");

  let marginPx = isEqual(username, userId) ? "10%" : "0px";
  let marginJustify = isEqual(username, userId)
    ? "justify-end"
    : "justify-start";

  return (
    <>
      <div
        className={` flex ${marginJustify}`}
        style={{ width: "90%", marginLeft: `${marginPx}` }}
      >
        <Comment
          author={username}
          avatar={<Avatar icon={<UserOutlined />} />}
          content={
            <p>
              {get(data, "content")}
              <br />

              {get(data, "url") ? (
                <LightBoxGallery images={compact(image)}>
                  {(data1, setCurrentIndex, setImageVisible) => {
                    return (
                      <img
                        src={get(data, "url")}
                        style={{ width: 200 }}
                        onClick={() => {
                          setImageVisible(true);
                        }}
                      />
                    );
                  }}
                </LightBoxGallery>
              ) : (
                ""
              )}
            </p>
          }
          datetime={
            <Tooltip title={moment(replyAt).format("DD/MM/YYYY hh:mm:ss A")}>
              <span>{moment(replyAt).format("DD/MM/YYYY hh:mm:ss A")}</span>
            </Tooltip>
          }
        />
      </div>
    </>
  );
}

export default ChatCard;
