import { apiUrl, routePrefix, _axios_base_get_list } from "..";

const PAGESIZE = 99;

export default function getEnquiry(
  limit = PAGESIZE,
  skip = 0,
  query = {},
  accessKey = ""
) {
  return _axios_base_get_list(
    `${apiUrl}/${routePrefix.enquiry}/getEnquiry`,
    limit,
    skip,
    query,
    accessKey
  );
}
