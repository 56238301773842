import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import CustomerServicePage from "../components/profile/pages/CustomerServicePage"
import 'react-image-lightbox/style.css';
import 'antd/dist/antd.css';

// markup
const CustomerService = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <CustomerServicePage />
        </React.Fragment>
    )
}

export default CustomerService
