
// const PAGESIZE = 30;

import { apiUrl, routePrefix, _base_axios_post } from "..";



export default function createEnquiryRequest(query = {}) {
  return _base_axios_post(
    `${apiUrl}/${routePrefix.enquiry}/createEnquiryRequest`,
    query
  );
}
