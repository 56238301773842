import { EyeFilled } from "@ant-design/icons";
import { Button, Form, Input, Modal, Spin, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { compact, forEach, get, isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Textfit } from "react-textfit";
import { upload_imageIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import upload from "../../../newApi/image/upload";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDate } from "../../../utilities/common-function";
import LightBoxGallery from "../../general/components/LightBoxGallery";
import ChatCard from "../../profile/components/ChatCard";

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener("load", () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return false;
// }

// markup
const MessageModal = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [form] = useForm();
  // const [imageVisible, setImageVisible] = useState(false);
  const [file, setFile] = useState();
  const user = get(props, "user.user");
  const [images, setImages] = useState([]);

  let replyMessages = get(props.data, "replyMessage")
    ? get(props.data, "replyMessage")
    : [];

  useEffect(() => {
    setVisible(props.visible === true);
    setShowMore(false);
    processImageArray();
  }, [props.visible]);

  useEffect(() => {
    processImageArray();
  }, [props]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function processImageArray() {
    let imagesData = get(props.data, "images");

    let tempImagesArr = [];
    setImages((prevState) => {
      return [];
    });

    forEach(imagesData, (item) => {
      if (isArray(imagesData)) {
        imagesData.map((item) => {
          if (!tempImagesArr.includes(get(item, "url")))
            tempImagesArr.push(get(item, "url"));
        });
      } else {
        if (!tempImagesArr.includes(get(item, "url")))
          tempImagesArr.push(imagesData);
      }

      setImages((prevState) => {
        return tempImagesArr;
      });
    });
  }

  function handleSubmit(values) {
    form
      .validateFields()
      .then((values) => {
        if (get(file, "[0].originFileObj")) {
          setLoading(true);

          upload({ image: get(file, "[0].originFileObj") }).then((res) => {
            let res_data = get(res, "data");
            let image_url = get(res_data, "url");
            let image_key = get(res_data, "key");

            let replyObject = {
              content: values.newReplyMessage,
              replyBy: get(user, "_id"),
              replyAt: new Date(),
              patchId: get(props.data, "_id"),
              oriReplyMessages: replyMessages,
              images: [{ url: image_url, key: image_key }],
              oriImages: get(props.data, "images"),
            };
            setImageUrl("");
            setFile([]);
            form.resetFields();

            if (props.onSubmit) {
              props.onSubmit(replyObject);
            } else {
              setVisible(false);
            }
          });
        } else {
          let replyObject = {
            content: values.newReplyMessage,
            replyBy: get(user, "_id"),
            replyAt: new Date(),
            patchId: get(props.data, "_id"),
            oriReplyMessages: replyMessages,
            images: [],
            oriImages: get(props.data, "images"),
          };
          setImageUrl("");
          setFile([]);
          form.resetFields();

          if (props.onSubmit) {
            props.onSubmit(replyObject);
          } else {
            setVisible(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (info) => {
    setFile(info.fileList);
    getBase64(info.file, (imageUrl) => {
      setImageUrl(imageUrl);
    });
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const _renderContent = () => {
    return (
      <div className="lightgreybox-bg-color rounded-3xl p-5 pt-10 text-base">
        <div className="flex justify-start items-center mb-3">
          <Textfit className="inline-block w-1/3 lg:w-1/4 text-base text-white font-semibold mr-3">
            {t("messageTitle", sourceKey.profile)}:
          </Textfit>
          <Textfit className="inline-block w-2/3 lg:w-3/4 text-base text-white font-semibold ">
            {get(props.data, "messageTitle")
              ? get(props.data, "messageTitle")
              : ""}
          </Textfit>
        </div>

        <div className="flex justify-start items-center mb-3">
          <Textfit className="inline-block w-1/3 lg:w-1/4 text-base text-white font-semibold mr-3">
            {t("messageContent", sourceKey.profile)}:
          </Textfit>
          <Textfit className="inline-block w-2/3 lg:w-3/4 text-base text-white font-semibold">
            {get(props.data, "userMessage")
              ? get(props.data, "userMessage")
              : ""}
          </Textfit>
        </div>

        <div className="flex justify-start items-center mb-3">
          <Textfit className="inline-block w-1/3 lg:w-1/4  text-base text-white font-semibold mr-3">
            {t("enquiryDate", sourceKey.profile)}:
          </Textfit>
          <Textfit className="inline-block w-2/3 lg:w-3/4 text-base text-white font-semibold">
            {get(props.data, "enquiryDate")
              ? formatDate(
                  get(props.data, "enquiryDate"),
                  "DD/MM/YYYY hh:mm:ss"
                )
              : ""}
          </Textfit>
        </div>

        {images.length > 0 ? (
          <div className="flex justify-start items-center mb-3">
            <Textfit className="inline-block w-1/3 lg:w-1/4 text-base text-white font-semibold mr-3">
              {t("uploadedMedia", sourceKey.profile)}:
            </Textfit>
            <Textfit className="inline-block w-2/3 lg:w-3/4 text-base text-white font-semibold">
              {
                <LightBoxGallery images={compact(images)}>
                  {(data, setCurrentIndex, setImageVisible) => {
                    return (
                      <EyeFilled
                        onClick={() => {
                          setImageVisible(true);
                        }}
                      />
                    );
                  }}
                </LightBoxGallery>
              }
            </Textfit>
          </div>
        ) : (
          ""
        )}

        <React.Fragment>
          <div className="text-base">
            <br />
            {t("response", sourceKey.profile)}
          </div>
          <div className="my-3">
            <div
              className="rounded-lg px-3 py-5 border"
              style={{ minHeight: 200 }}
            >
              {replyMessages.map((record, index) => (
                <ChatCard
                  key={index}
                  data={record}
                  userId={get(user, "username")}
                />
              ))}
            </div>
          </div>

          {!props.showResponse ? null : (
            <div className="mt-8">
              <Form form={form}>
                <div className="text-base text-white">
                  {" "}
                  {t("response", sourceKey.profile)}:
                </div>
                <div className="my-3 ml-24">
                  <Form.Item
                    name="newReplyMessage"
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {t("inputMessageContent", sourceKey.profile)}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{ background: "#20262F", color: "white" }}
                      rows={4}
                      className={"input-border"}
                    />
                  </Form.Item>
                </div>

                <div
                  className="my-3 absolute lightgreybox-bg-color"
                  style={{ left: "10px", bottom: "70px" }}
                >
                  <Form.Item
                    style={{ margin: 0 }}
                    name="imageUpload"
                    rules={[
                      {
                        required: false,
                        message: <React.Fragment></React.Fragment>,
                      },
                    ]}
                  >
                    <Upload
                      // name="avatar"
                      listType="picture-card"
                      // className="avatar-uploader"
                      showUploadList={false}
                      accept="image/*"
                      fileList={file}
                      style={{ width: "100%" }}
                      beforeUpload={(file) => false}
                      onChange={handleChange}
                    >
                      {" "}
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <img
                          src={upload_imageIcon}
                          style={{ width: 100, height: 80 }}
                        />
                      )}
                    </Upload>
                  </Form.Item>
                </div>
                <div className="mt-10">
                  <Button
                    className="main-button-color input-border"
                    block
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    {t("reply", sourceKey.profile)}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <MediaQuery maxWidth={670}>
          <Modal
            wrapClassName="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="80%"
            mask
            footer={null}
            className=""
            visible={visible}
            onCancel={() => {
              close();
            }}
          >
            {_renderContent()}
          </Modal>
        </MediaQuery>
        <MediaQuery minWidth={671}>
          <Modal
            wrapClassName="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="60%"
            mask
            footer={null}
            className=""
            visible={visible}
            onCancel={() => {
              close();
            }}
          >
            {_renderContent()}
          </Modal>
        </MediaQuery>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
